import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { getMode } from '@/utils/common'
import * as communication from '@/utils/communication'
import * as api from '@/api/user'
import { getLocalstorage } from '@/utils/utils';

const ShopCarCountContext = createContext({
  count: 0,
  update() {}
})

export const useShopCarCountContext = () => {
  return useContext(ShopCarCountContext)
}

export const ShopCarCountProvider = ({children}) => {
  const [shopCarCount, setShopCarCount] = useState(0)
  

  const update = useCallback(async() => {
    const userinfo = getLocalstorage('loginUserInfo');
    if(!userinfo) {
      return
    }
    const res = await api.updateShopCarCount()
    if(res.success) {
      setShopCarCount(res.data)
      // 如果是子应用，则需要通知父应用，如果是父应用，则需要通知子应用
      if(getMode() === 'app') {
        communication.deployMessageEventListener('shopCarCountChange', res.data)
      } else {
        communication.deployMessageEventListener('initShopCarCount', res.data)
      }
    }
  }, [])

  useEffect(() => {
    const setFn = (e, value) => {
      setShopCarCount(value)
    }
    // 子系统加载后默认获取
    const initFn = () => {
      communication.deployMessageEventListener('initShopCarCount', shopCarCount)
    }
    // 监听购物车数量
    if(getMode() === 'base') {
      communication.addMesageEventListener('shopCarCountChange', setFn)
      communication.addMesageEventListener('initShopCarCount', initFn)
      update()
      return () => {
        communication.removeMesageEventListener('shopCarCountChange', setFn)
        communication.removeMesageEventListener('initShopCarCount',initFn)
      }
    } else {
      const updateFn = (e, v) => {
        setShopCarCount(v)
      }
      communication.addMesageEventListener('initShopCarCount', updateFn)
      communication.deployMessageEventListener('initShopCarCount')
      return () => {
        communication.removeMesageEventListener('initShopCarCount', updateFn)
      }
    }
  }, [shopCarCount, update])

  return (
    <ShopCarCountContext.Provider value={{count: shopCarCount, update: update}}>
      {children}
    </ShopCarCountContext.Provider>
  );
}
