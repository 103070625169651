import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import '@/assets/styles/index.less'
import 'moment/locale/zh-cn'
import { Context, defaultStore } from '@/store'
import { ShopCarCountProvider } from '@/store/ShopCarCount'
import RouterEnter from '@/middlewares/RouterEnter'
import Router from '@/router'
import '@/middlewares/communication'
import { LanguageProvider } from '@/store/Lang'

if(process.env.REACT_APP_ENV === 'mock') {
  require('./mock')
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Context.Provider value={defaultStore}>
      <BrowserRouter>
        <RouterEnter>
          <LanguageProvider>
            <ShopCarCountProvider>
              <Router />
            </ShopCarCountProvider>
          </LanguageProvider>
        </RouterEnter>
      </BrowserRouter>
    </Context.Provider>
  // </React.StrictMode>
);
