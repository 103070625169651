import {stringify} from 'qs';
import JSEncrypt from 'jsencrypt'
import http from '@/utils/requestApi'
import http2 from '@/utils/request'
import * as config from '@/config/config'
import { getLocalstorage } from '@/utils/utils';
import { getUserInfo } from '@/utils/common'

// 用户登录
export const login = (params) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(config.pubKey);
  const encrypted = encrypt.encrypt(stringify(params));
  return http(`/custom/user/login`, {
    params: {
      p: encrypted
    }
  })
}

/** 获取当前的用户 */
export async function queryCurrentUser() {
  let loginUserInfo: any = getUserInfo()
  // return Promise.resolve().then(() => ({"name":loginUserInfo.zhmc,...loginUserInfo,status:"ok"}))
  const params={id:loginUserInfo.userid}
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(config.pubKey);
  const encrypted = encrypt.encrypt(stringify(params));
  return http(`/custom/user/currentUser`, {
    params: {
      id:loginUserInfo.userid
    }
  })
}

// 更新购物车数量
export const updateShopCarCount = () => {
  const userinfo = getUserInfo()
  return http2('/custom/gwc/countGwccp', {
    method: 'post',
    data: {
      khbm: userinfo.partner
    }
  })
}

// 修改密码
export const changePassword = (mm) => {
  const userinfo = getUserInfo()
  return http2('/custom/user/setMm', {
    method: 'post',
    data: {
      sysId: userinfo.sys_id,
      mm,
      updateMc: userinfo.zhmc
    }
  })
}
