import { createContext, useContext as uc } from 'react'

export const defaultStore = {
  mode: window.top === window.self ? 'base' : 'app',
  userInfo: null,
  lang: 'zhCN'
}

export const Context = createContext(defaultStore)

export const useContext = () => {
  return uc(Context)
}
