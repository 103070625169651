import { defaultStore } from '@/store'
import * as communication from '@/utils/communication'
import { getLocalstorage } from '@/utils/utils'
const win = window as any

export const registryBaseCommunication = (context) => {
  communication.addMesageEventListener('userInfo', (target) => {
    if(target.key) {
      communication.postMessageToChild('userInfo', {
        ...context.userInfo,
        ...getLocalstorage('loginUserInfo')
      }, target.key)
    }
  })
}

/**
 * @description 监听抽屉
 */
const drawEventListener = () => {
  let timer: any = null
  const observer = new MutationObserver(() => {
    if(timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      const drawers = document.querySelectorAll('.ant-drawer')
      communication.deployMessageEventListener('drawVisible', Array.prototype.some.call(drawers, (el) => el.offsetWidth))
    }, 100)
  })
  observer.observe(document.body, {
    childList: true,
    subtree: true
  })
}

// 基座应用执行
const baseInit = () => {
  communication.addMesageEventListener('dispatchId', () => {
    const iframes = document.querySelectorAll('#layout-iframe-container iframe')
    for(let i=0; i<iframes.length; i++) {
      communication.postMessageToChild('dispatchId', iframes[i].id, iframes[i].id)
    }
  })
}

// 子应用执行
const childInit = () => {
  communication.addMesageEventListener('dispatchId', (e, data) => {
    win.globalDataId = data
  })
  communication.deployMessageEventListener('dispatchId')
}

const init = () => {
  communication.initMessage()
  if(defaultStore.mode === 'base') {
    baseInit()
  } else {
    childInit()
    drawEventListener()
  }
}

init()

export const useCommuncation = () => {
  return {
    queryUserInfo() {
      return communication.queryInfoFromBase('userInfo')
    }
  }
}
