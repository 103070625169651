import React, { createContext, useContext, useEffect, useState } from 'react'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import enUS from 'antd/lib/locale-provider/en_US'
import * as communication from '@/utils/communication'
import { getMode } from '@/utils/common'
import { getLocalstorage, setLocalstorage } from '@/utils/utils'

const LanguageContext = createContext<{
  currentLanguage:  'enUS' | 'zhCN'
  changeLanguage: (lang: string) => void
}>({
  currentLanguage: 'enUS',
  changeLanguage(newLanguage) {}
})

const langMap = {
  zhCN,
  enUS
}

export const getLang = () => {
  return getLocalstorage('lang') || 'enUS'
}

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState(getMode() === 'base' ? getLang() : 'zhCN')

  const changeLanguage = (newLanguage) => {
    setCurrentLanguage(newLanguage)
    setLocalstorage('lang', newLanguage)
    communication.deployMessageEventListener('langChange', newLanguage)
  }

  // 如果是子应用，获取父级设置的语言
  useEffect(() => {
    if(getMode() === 'app') {
      const setLang = (e, value) => {
        setCurrentLanguage(value)
        setLocalstorage('lang', value)
      }
      communication.addMesageEventListener('langChange', setLang)
      communication.deployMessageEventListener('getLang')
      return () => {
        communication.removeMesageEventListener('langChange', setLang)
      }
    }
  }, [])

  // 如果是父页面，注册发送lang
  useEffect(() => {
    if(getMode() === 'base') {
      const getLang = (e) => {
        communication.deployMessageEventListener('langChange', currentLanguage, e.key)
      }
      communication.addMesageEventListener('getLang', getLang)
      return () => {
        communication.removeMesageEventListener('langChange', getLang)
      }
    }
  }, [currentLanguage])

  return (
    <LanguageContext.Provider value={{ currentLanguage, changeLanguage }}>
      <ConfigProvider locale={langMap[currentLanguage]}>
        {children}
      </ConfigProvider>
    </LanguageContext.Provider>
  );
}

export const useLangContext = () => {
  return useContext(LanguageContext)
}

export default LanguageContext