import { FC, PropsWithChildren, useCallback, useState } from "react"
import { Spin, Form, Input, Button, message } from 'antd'
import * as utils from '@/utils/utils'
import { getUserInfo } from '@/utils/common'
import * as  api from '@/api/user'
import {useLang} from '@/utils/lang'

type PropType = FC<PropsWithChildren<{
  onClose: () => void
}>>

const ChangePassword: PropType = ({onClose}) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const lang = useLang()

  const handleSave = useCallback(async () => {
    await form.validateFields()
    const data = form.getFieldsValue()
    if(data.mm.length < 6) {
      utils.warnAlert(lang.Configuration.mmcd)
      return
    }
    if(data.mm !== data.again) {
      utils.warnAlert(lang.Configuration.qrmmyz)
      return
    }
    setLoading(true)
    const res = await api.changePassword(data.mm).finally(() => {
      setLoading(false)
    })
    if (res.success) {
      message.success(res.message)
      onClose()
    }
  }, [form, onClose])

  return (
    <Spin spinning={loading}>
      <Form form={form} labelCol={{span: 5}}>
        <div style={{ maxWidth: '800px', margin: "0 auto" }}>
          <Form.Item name='mm' label={lang.Configuration.xmm} rules={[{required: true, message: lang.Configuration.qsr}]}>
            <Input.Password placeholder={lang.Configuration.qsr} allowClear minLength={6} />
          </Form.Item>
          <Form.Item name='again' label={lang.Configuration.qrxmm} rules={[{required: true, message: lang.Configuration.qsr}]}>
            <Input.Password placeholder={lang.Configuration.qsr} allowClear minLength={6} />
          </Form.Item>
        </div>
      </Form>
      <div className='text-center'>
        <Button type="primary" className='mlr-30' onClick={handleSave}>{lang.Configuration.bc}</Button>
        <Button className='mlr-30' onClick={onClose}>{lang.Configuration.qx}</Button>
      </div>
    </Spin>
  )
}

export default ChangePassword
