import { defaultStore } from '@/store/index'

const events = {}
const win = window as any

// 向基座发送消息
const postMessageToTop = (type, data={}) => {
  window.parent.postMessage({
    type,
    data,
    target: {
      key: win.globalDataId
    }
  })
}

// 向指定子应用发送消息
export const postMessageToChild = (type, data?, key?) => {
  if(key) {
    const frame: any = document.querySelector('#' + key)
    if(frame) {
      frame.contentWindow.postMessage({
        type,
        data,
        target: {
          isParent: true
        }
      }, frame.dataset.host)
    }
  } else {
    const iframes = document.querySelectorAll('#layout-iframe-container iframe')
    for(let i=0; i<iframes.length; i++) {
      const frame = iframes[i] as any
      frame.contentWindow.postMessage({
        type,
        data,
        target: {
          isParent: true
        }
      }, frame.dataset.host)
    }
  }
}

export const initMessage = () => {
  window.addEventListener('message', ({data: {data, type, target}}) => {
    if(events[type]) {
      events[type].forEach(handler => {
        if(typeof handler === 'function') {
          handler(target, data)
        }
      })
    }
  })
}

export const addMesageEventListener = (key, handler) => {
  if(!events[key]) {
    events[key] = []
  }
  events[key].push(handler)
}

export const deployMessageEventListener = (key, data?, childKey?) => {
  // 如果是子应用，固定向基座应用发消息，如果是基座应用，则传入id表示向指定子应用发消息，否则向所有子应用发消息
  if(defaultStore.mode === 'base') {
    postMessageToChild(key, data, childKey)
  } else {
    postMessageToTop(key, data)
  }
}

export const removeMesageEventListener = (key, handler) => {
  if(events[key]) {
    events[key].splice(events[key].findIndex(h => h === handler), 1)
  }
}

// 子系统向基座应用获取指定数据
export const queryInfoFromBase = (key) => {
  deployMessageEventListener(key)
  return new Promise((resolve) => {
    addMesageEventListener(key, (e, data) => {
      resolve(data)
    })
  })
}

// 打开新页面
export const addTab = (path, name?, code='wm') => {
  deployMessageEventListener('addTab', {
    path,
    name,
    code
  })
}
