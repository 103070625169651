import { FC, PropsWithChildren, useCallback, useState } from 'react'
import 'file-power-boundle'
import LeftMenu from './components/LeftMenu'
import TagBox from './components/TagBox'
import Head from './components/Head'
import style from './style.module.less'

type PropType = FC<PropsWithChildren>

const getFullUrl = (host: string, path: string) => {
  if(!host) {
    return ''
  }
  return [host.replace(/\/$/, ''), path.replace(/^\//, '')].join('/')
}

const BaseLayout: PropType = () => {
  const [activeMenu, setActiveMenu] = useState(null)
  const [iframes, setIframes] = useState([])
  const [iframeKey, setIframeKey] = useState('')
  const [headVisible, setHeadVisible] = useState(true)

  const menuChange = useCallback((menu) => {
    setActiveMenu(menu)
  }, [])

  // 切换页签
  const handleTagChange = useCallback((menu, tags) => {
    setActiveMenu(menu)
    let curIframes = [...iframes]
    if(menu) {
      setIframeKey(menu.key)
      if(iframes.every(iframe => iframe.key !== menu.key)) {
        curIframes.push({
          ...menu,
          fullUrl: getFullUrl(menu.host || '', menu.url || ''),
        })
      }
    }
    if(tags) {
      // 比对清理多余的iframe
      for(let i=curIframes.length-1; i>-1; i--) {
        if(tags.every(tag => tag.key !== curIframes[i].key)) {
          curIframes.splice(i, 1)
        }
      }
    }
    setIframes(curIframes)
  }, [iframes])

  // 打开购物车
  const handleShopcar = useCallback(() => {
    setActiveMenu({
      key: 'shopcar',
      label: '购物车',
      host: window.location.origin,
      url: '/shopcar',
      target: 'inner'
    })
  }, [])

  return (
    <div className='h-full flex bg-white'>
      <LeftMenu onChange={menuChange} active={activeMenu && activeMenu.key || ''} visible={headVisible} />
      <div className='flex-1 flex flex-column w-0'>
        
        <TagBox active={activeMenu} onChange={handleTagChange} onVisibleChange={(visible) => setHeadVisible(visible)} />
        <Head onShopcar={handleShopcar} visible={headVisible} />
        <div className={'flex-1 h-0 p-10 ' + style.container} id="layout-iframe-container">
          {
            activeMenu && <iframe data-host={activeMenu.host} id={activeMenu.key} src={getFullUrl(activeMenu.host || '', activeMenu.url || '')} className='h-full w-full' title={activeMenu.label} allowFullScreen allowTransparency frameBorder="0" />
          }
          {/* {
            iframes.map((item) => <iframe data-host={item.host} id={item.key} key={item.key} src={item.fullUrl} style={{display: item.key === iframeKey ? 'block' : 'none'}} className='h-full w-full' title={item.label} allowFullScreen allowTransparency frameBorder="0" />)
          } */}
        </div>
      </div>
    </div>
  )
}

export default BaseLayout
