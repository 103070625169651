import { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { Menu } from 'antd'
import { CodepenOutlined, FundOutlined, ContainerOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import style from './style.module.less'
import { useLang } from '@/utils/lang'
import { useLangContext } from '@/store/Lang'
import * as commonUtils from '@/utils/common'
import * as communication from '@/utils/communication'

type PropType = FC<PropsWithChildren<{
  onChange?: (menu: any) => void
  active: string
  visible?: boolean
}>>

const itemsData = [
  {
    icon: <FundOutlined />,
    key: '工作台',
    labelkey: 'menu.gzt'
  },
  {
    icon: <CodepenOutlined />,
    key: '东成产品',
    labelkey: 'menu.dccp',
    children: [

      {
        key: '东成产品-整机',
        labelkey: 'menu.dccpZj',
        host: window.location.origin,
        systemCode: 'wm',
        url: '/production?type=zj&detail=drawer',
        children: [
          {
            key: '东成产品-整机-直流',
            labelkey: 'menu.dccpZjZl',
            host: window.location.origin,
            systemCode: 'wm',
            url: '/production?type=zj&detail=drawer&cpxl=直流',
          },
          {
            key: '东成产品-整机-交流',
            labelkey: 'menu.dccpZjJl',
            host: window.location.origin,
            systemCode: 'wm',
            url: '/production?type=zj&detail=drawer&cpxl=交流',
          },
        ]
      },
      {
        key: '东成产品-转定子',
        labelkey: 'menu.dccpZdz',
        host: window.location.origin,
        systemCode: 'wm',
        url: '/production?type=zdz&detail=drawer',
      },
      // {
      //   key: '东成产品-零配件',
      //   labelkey: 'menu.dccpLpj',
      //   host: window.location.origin,
      //   systemCode: 'wm',
      //   url: '/productionLpj?type=lpj',
      // },
      {
        key: '东成产品-附件',
        labelkey: 'menu.dccpFj',
        host: window.location.origin,
        systemCode: 'wm',
        url: '/production?type=fj&detail=drawer',
      },
      {
        key: '东成产品-手动工具',
        labelkey: 'menu.dccpSdgj',
        host: window.location.origin,
        systemCode: 'wm',
        url: '/production?type=sdgj&detail=drawer',
      }
    ]
  },
  {
    icon: <ContainerOutlined />,
    key: '订单中心',
    labelkey: 'menu.ddzx',
    children: [
      {
        key: '订单中心-下单记录',
        labelkey: 'menu.ddzxxdjl',
        host: window.location.origin,
        systemCode: 'wm',
        url: '/order/xdjl',
      },
      {
        key: '订单中心-预购单',
        labelkey: 'menu.ddzxygd',
        host: window.location.origin,
        systemCode: 'wm',
        url: '/order/yg',
      },
      {
        key: '订单中心-发运单',
        labelkey: 'menu.ddzxfyd',
        host: window.location.origin,
        systemCode: 'wm',
        url: '/order/fyd',
      },
      {
        key: '订单中心-在手订单查询',
        labelkey: 'menu.ddzxdd',
        host: window.location.origin,
        systemCode: 'wm',
        url: '/order/dd',
      }

    ]
  }, {
    hidden: true,
    key: 'shopcar',
    labelkey: 'menu.shopcar',
    host: window.location.origin,
    systemCode: 'wm',
    url: '/shopcar',
  }
]

const getAllPageRoutes = (items) => {
  let result = []
  for(let i=0; i<items.length; i++) {
    if(items[i].url) {
      result.push({
        ...items[i],
        children: undefined
      })
    }
    if(items[i].children) {
      result = result.concat(getAllPageRoutes(items[i].children))
    }
  }
  return result
}

const LeftMenu: PropType = ({ onChange, active, visible }) => {
  const [items, setItems] = useState([])
  const [collapsed, setCollapsed] = useState(false)
  const langContext = useLangContext()
  const lang = useLang()
  const globalRef = useRef({
    allRoutes: []
  })

  useEffect(() => {
    setTimeout(() => {
      if(typeof onChange === 'function') {
        onChange({
          key: '工作台',
          label: '工作台',
          host: window.location.origin,
          url: '/',
          target: 'inner'
        })
      }
    })
  }, [])

  // 注册打开页面的方法
  useEffect(() => {
    const run = (e, {name, path, code}) => {
      const routeCfg = globalRef.current.allRoutes.find((r) => r.systemCode === code && r.url === path)
      onChange({
        key: routeCfg.key,
        label: name || routeCfg.label,
        host: routeCfg.host,
        url: path,
        target: 'inner'
      })
    }
    communication.addMesageEventListener('addTab', run)
    return () => {
      communication.removeMesageEventListener('addTab', run)
    }
  }, [onChange])

  useEffect(() => {
    const run = (menus) => {
      for(let i=0; i<menus.length; i++) {
        menus[i].label = commonUtils.readValue(lang, menus[i].labelkey)
        if(Array.isArray(menus[i].children)) {
          run(menus[i].children)
        }
      }
    }
    run(itemsData)
    setItems([...itemsData])
    globalRef.current.allRoutes = getAllPageRoutes(itemsData)
  }, [langContext.currentLanguage, lang])

  // 展开收起
  const handleCollase = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed])

  // 点击菜单
  const handleMenu = useCallback((menu) => {
    const data = menu.item.props
    if(data.target === 'outer') {
      window.open(data.url, '_blank')
    } else {
      if(typeof onChange === 'function') {
        // 查询对应的菜单数据
        let menuData = null
        let list = items
        const keyPath = menu.keyPath || []
        while(keyPath.length && list) {
          const key = keyPath.pop()
          menuData = list.find(row => row.key === key)
          list = menuData.children
        }
        onChange(menuData)
      }
    }
  }, [items, onChange])

  return (
    <div className={`${style.wrap} ${collapsed ? style.collapsed : ''}`}>
      {
        visible ? (
          <div className={style.collase} onClick={handleCollase}>
            {
              collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
            }
          </div>
        ) : <></>
      }
      <div className={`${style.head}`}>
        <img className={style.logo} src="/logo.png" alt="" />
        {
          collapsed ? <></> : <h1>DMS</h1>
        }
      </div>
      <div className='overflow-auto-y h-0 flex-1'>
        <Menu
          mode="inline"
          items={items}
          inlineCollapsed={collapsed}
          onClick={handleMenu}
          selectedKeys={[active]}
        />
      </div>
    </div>
  )
}

export default LeftMenu
