import { Modal, Input, Button } from "antd"
import Highlighter from 'react-highlight-words'
import { localStorageKey } from '@/config/config'
import { getLang } from "@/utils/lang";
export const successAlert = (message: any, callback?: () => void) => {
  const lang = getLang()
  Modal.success({
    title: lang.Configuration.ts,
    content: message,
    onOk() {
      if (callback && typeof callback === 'function') {
        callback();
      }
    },
  })
}
export const warnAlert = (message) => {
  const lang = getLang()
  Modal.warning({
    title: lang.Configuration.ts,
    content: message
  })
}

export const getBzfh = (bz) => {
  if (bz === "美元") {
    return "USD"
  }
  if (bz === "人民币") {
    return "CNY"
  }
  if (bz === "跨境人民币") {
    return "CNH"
  }
  if (bz === "欧元") {
    return "EUR"
  }
  return ""
}

export const alert = (title, message, callback?) => {
  Modal.info({
    title,
    content: (
      <div>
        <p>{message}</p>
      </div>
    ),
    onOk() {
      if (callback && typeof callback === 'function') {
        callback();
      }
    },
  });
}

export const confirm = (title, message, okcallback, cancelcallback?) => {
  Modal.confirm({
    title,
    content: (
      <div>
        <p>{message}</p>
      </div>
    ),
    okText: "OK",
    cancelText: "Cancel",
    onOk() {
      if (okcallback && typeof okcallback === 'function') {
        okcallback();
      }
    },
    onCancel() {
      if (cancelcallback && typeof cancelcallback === 'function') {
        cancelcallback();
      }
    },
  });
}

export const getLocalstorage = (key) => {
  const val = window.localStorage.getItem(localStorageKey + key)
  if(!val) {
    return null
  }
  return JSON.parse(val).value
}

export const setLocalstorage = (key, value) => {
  window.localStorage.setItem(localStorageKey + key, JSON.stringify({
    value
  }))
}

let searchText = ''
const handleCloumnSearch = (selectedKeys, confirm) => {
  confirm();
  // this.setState({ searchText: selectedKeys[0] });
  searchText = selectedKeys[0];
};

const handleCloumnReset = clearFilters => {
  clearFilters();
  // this.setState({ searchText: '' });
  searchText = "";
};

export const getColumnSearchProps = (dataIndex, placeholder) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          // this.searchInput = node;
        }}
        placeholder={`请输入${placeholder}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleCloumnSearch(selectedKeys, confirm)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => handleCloumnSearch(selectedKeys, confirm)}
        icon="search"
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        搜索
      </Button>
      <Button onClick={() => handleCloumnReset(clearFilters)} size="small" style={{ width: 90 }}>
        重置
      </Button>
    </div>
  ),
  // filterIcon: filtered => (
  //   <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
  // ),
  onFilter: (value, record) => {
    if (record[dataIndex]) {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    }
    return false;
  },
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      // setTimeout(() => this.searchInput.select());
    }
  },
  render: text => {
    // const { searchText } = this.state;
    return <Highlighter
      highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ""}
    />
  },
});

export const compute = (n1, n2, fh) => {
  if(!n1){
    n1=0
  }
  if(!n2){
    n2=0
  }
  n1 = Number(n1);
  n2 = Number(n2);
  const n1l = n1.toString().split(".");
  const n2l = n2.toString().split(".");
  let pow1 = 0;
  let base1 = 0;
  if (fh === "+") {
    if (n1l.length > 1 && n2l.length > 1) {
      pow1 = Math.max(n1l[1].length, n2l[1].length);
      base1 = Math.pow(10, pow1);
      return (Math.round(n1 * base1) + Math.round(n2 * base1)) / base1;
    }
    if (n1l.length > 1) {
      base1 = Math.pow(10, n1l[1].length);
      return (Math.round(n1 * base1) + Math.round(n2 * base1)) / base1;
    }
    if (n2l.length > 1) {
      base1 = Math.pow(10, n2l[1].length);
      return (Math.round(n1 * base1) + Math.round(n2 * base1)) / base1;
    }
    return Math.round(n1 + n2);
  }
  if (fh === "-") {
    if (n1l.length > 1 && n2l.length > 1) {
      pow1 = Math.max(n1l[1].length, n2l[1].length);
      base1 = Math.pow(10, pow1);
      return (Math.round(n1 * base1) - Math.round(n2 * base1)) / base1;
    }
    if (n1l.length > 1) {
      base1 = Math.pow(10, n1l[1].length);
      return (Math.round(n1 * base1) - Math.round(n2 * base1)) / base1;
    }
    if (n2l.length > 1) {
      base1 = Math.pow(10, n2l[1].length);
      return (Math.round(n1 * base1) - Math.round(n2 * base1)) / base1;
    }
    return Math.round(n1 - n2);
  }
  if (fh === "*") {
    if (n1l.length > 1 && n2l.length > 1) {
      pow1 = Math.max(n1l[1].length, n2l[1].length);
      base1 = Math.pow(10, pow1);
      return (Math.round(n1 * base1) * Math.round(n2 * base1)) / Math.round(base1 * base1);
    }
    if (n1l.length > 1) {
      base1 = Math.pow(10, n1l[1].length);
      return Math.round(n1 * base1 * n2) / base1;
    }
    if (n2l.length > 1) {
      base1 = Math.pow(10, n2l[1].length);
      return Math.round(n2 * base1 * n1) / base1;
    }
    return Math.round(n1 * n2);
  }
  if (fh === "/") {
    if (n1l.length > 1 && n2l.length > 1) {
      pow1 = Math.max(n1l[1].length, n2l[1].length);
      base1 = Math.pow(10, pow1);
      return Math.round(n1 * base1) / Math.round(n2 * base1);
    }
    if (n1l.length > 1) {
      base1 = Math.pow(10, n1l[1].length);
      return Math.round(n1 * base1) / Math.round(n2 * base1);
    }
    if (n2l.length > 1) {
      base1 = Math.pow(10, n2l[1].length);
      return Math.round(n1 * base1) / Math.round(n2 * base1);
    }
    return Math.round(n1) / Math.round(n2);
  }
}

export const numberFormat = (num, ws, display = false) => {
  const n1 = Number(num).toFixed(ws);
  if (display === true) {
    return n1;
  }
  return Number(n1);

}