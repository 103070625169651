import { Outlet } from "react-router-dom"
import BaseLayout from "@/layouts/BaseLayout"
import { getMode } from '@/utils/common'

function App() {

  return getMode() === 'base' ? <BaseLayout /> : <Outlet />
}

export default App;
