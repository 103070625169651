import React from 'react'
import { useRoutes } from "react-router-dom"
import App from '@/App'

let lazyload=(path)=>{
  let Com=React.lazy(()=>{return import (`../pages/${path}`)})
  return (
    <React.Suspense fallback={<>请等待·····</>}>
      <Com/>
    </React.Suspense>
  )
}

const routes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: lazyload('Home')
      },
      {
        path: '/order/yg',
        element: lazyload('Order/Yg')
      },
      {
        path: '/order/xdjl',
        element: lazyload('Order/Xdjl')
      },
      {
        path: '/order/dd',
        element: lazyload('Order/Dd')
      },
      {
        path: '/order/fyd',
        element: lazyload('Order/Fyd')
      },
      {
        path: '/production',
        element: lazyload('Production')
      },
      {
        path: '/productionLpj',
        element: lazyload('Production/indexLpj')
      },
      {
        path: '/production/detail',
        element: lazyload('Production/Detail')
      },
      {
        path: '/shopcar',
        element: lazyload('ShopCar')
      }
    ]
  },
  {
    path: '/login',
    element: lazyload('Login')
  },
]

const Router = () => {
  return useRoutes(routes)
}

export default Router
