import { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { Tabs } from 'antd'
import * as communication from '@/utils/communication'
import style from './style.module.less'

type PropType = FC<PropsWithChildren<{
  active?: any
  onChange?: (menu: any, tabs: any[]) => void
  onVisibleChange?: (visib: boolean) => void
}>>

const TagBox: PropType = ({ active, onChange, onVisibleChange }) => {
  const [tabs, setTabs] = useState([])
  const [activeKey, setActiveKey] = useState('')
  const [tagVisible, setTagVisible] = useState(true)
  const globalRef = useRef({
    drawerMap: {},
    tabs: []
  })

  useEffect(() => {
    if(active && !active.key) {
      active.key = 'temp-' + Date.now()
    }
    setActiveKey(active ? active.key : '')
    if(active) {
      if(globalRef.current.tabs.every(tab => tab.key !== active.key)) {
        globalRef.current.tabs.push({
          ...active
        })
        setTabs([...globalRef.current.tabs])
      }
    }
  }, [active])

  useEffect(() => {
    setTagVisible(!globalRef.current.drawerMap[activeKey])
  }, [activeKey])

  useEffect(() => {
    if(typeof onVisibleChange === 'function') {
      onVisibleChange(tagVisible)
    }
  }, [tagVisible, onVisibleChange])

  useEffect(() => {
    if(typeof onChange === 'function') {
      onChange(globalRef.current.tabs.find(tab => tab.key === activeKey), globalRef.current.tabs)
    }
  }, [activeKey])

  // 点击切换tab
  const handleTabClick = useCallback((key) => {
    if(activeKey === key) {
      return
    }
    setActiveKey(key)
  }, [activeKey])

  // 删除tab
  const handleTabItemChange = useCallback((key) => {
    const index = globalRef.current.tabs.findIndex(tab => tab.key === key)
    if(key === activeKey) {
      if(index === 0) {
        setActiveKey(globalRef.current.tabs[1] ? globalRef.current.tabs[1].key : '')
      } else {
        setActiveKey(globalRef.current.tabs[index-1].key)
      }
    }
    globalRef.current.tabs.splice(index, 1)
    delete globalRef.current.drawerMap[key]
    setTabs([...globalRef.current.tabs])
  }, [activeKey])

  useEffect(() => {
    const callback = (e, visible) => {
      globalRef.current.drawerMap[e.key] = visible
      if(activeKey === e.key) {
        setTagVisible(!visible)
      }
    }
    communication.addMesageEventListener('drawVisible', callback)
    return () => {
      communication.removeMesageEventListener('drawVisible', callback)
    }
  }, [activeKey])

  return (
    <div className={`${style.tagbox} border-bottom pl-20 pt-4 ${tagVisible ? style.hidden : style.hidden}`}>
      <Tabs
        activeKey={activeKey}
        items={tabs}
        onTabClick={handleTabClick}
        onEdit={handleTabItemChange}
        type="editable-card"
      >
      </Tabs>
    </div>
  )
}

export default TagBox
