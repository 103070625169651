import { PropsWithChildren, useEffect, FC, useState, useCallback } from 'react'
import { useLocation,useNavigate } from "react-router-dom"
import { message } from "antd"
import { getLocalstorage } from '@/utils/utils'
import { useContext } from '@/store'
import { registryBaseCommunication, useCommuncation } from './communication'
import * as api from '@/api/user'
import * as utils from "@/utils/utils";
 
// 去往登录页的组件
function ToLogin(){
  const navigateTo = useNavigate()
  const location = useLocation()
  useEffect(()=>{
    navigateTo("/login?f=" + location.pathname);
    message.warning("您还没有登录，请登录后再访问！");
  },[])
  return <div></div>
}

// 获取用户信息
const UserInfo = ({view}) => {
  const [isUpdate, setIsUpdate] = useState(false)
  const context = useContext()
  const comu = useCommuncation()

  useEffect(() => {
    // 更新用户信息
    if(context.mode === 'base') {
      updateUserInfo().then(() => {
        // 执行通讯相关
        registryBaseCommunication(context)
      })
    } else {
      setTimeout(() => {
        comu.queryUserInfo().then((data) => {
          context.userInfo = data
            setIsUpdate(true)
          })
        }, 10)
      }
  }, [])



  // 更新用户信息
  const updateUserInfo = useCallback(async () => {
    const res = await api.queryCurrentUser()
    if(res) {
      if(res.status === 'ok') {
        context.userInfo = res
        utils.setLocalstorage('loginUserInfo', res)
        setIsUpdate(true)
      }else {
        context.userInfo = {
          "sys_id": "01f8468399454c6196a18ea0d36c17b6",
          "lx": "外贸销售",
          "status": "ok",
          "errmsg": null,
          "currentAuthority": null,
          "zhmc": "测试",
          "dlm": "cs",
          "openid": null,
          "userid": "01f8468399454c6196a18ea0d36c17b6",
          "gnjs": null,
          "partner": "0002000018",
          "pp": "DCA",
          "bz": null
        }
        setIsUpdate(true)
        // message.error(res.errmsg)
        // setTimeout(() => {
        //   window.location.href="/login"
        // }, 1000)
      }

    }
  }, [context.userInfo])

  return isUpdate ? view : <></>
}
 
// 手写封装路由守卫
const BeforeRouterEnter: FC<PropsWithChildren> = ({children}) => {
    const location = useLocation()
    let userinfo = getLocalstorage('loginUserInfo');
    if(location.pathname!=="/login" && !userinfo){
      return <ToLogin />
    }
    if(location.pathname === "/login") {
      return children
    }
    return <UserInfo view={children} />
}
 
export default BeforeRouterEnter