import { useEffect, useState } from 'react'
import { useLangContext, getLang as getLangCore } from '@/store/Lang'
import enUS from '@/language/enUS'
import zhCN from '@/language/zhCN'

export const useLang = () => {
  const langContext = useLangContext()
  const [lang, setLang] = useState(zhCN)

  useEffect(() => {
    setLang(langContext.currentLanguage === 'enUS' ? enUS : zhCN)
  }, [langContext.currentLanguage])

  return lang
}

export const getLang = () => {
  if(getLangCore() === 'enUS') {
    return enUS
  }
  return zhCN
}
