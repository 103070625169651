import { getLocalstorage } from '@/utils/utils'

export const getMode = () => {
  return window.top === window.self ? 'base' : 'app'
}

// 字符串key读取属性
export const readValue = (obj, keys) => {
  if(typeof keys !== 'string') {
    return undefined
  }
  if(!keys) {
    return undefined
  }
  let target = obj
  const fileds = keys.split('.')
  for(let i=0; i<fileds.length; i++) {
    if(i === fileds.length - 1) {
      return target[fileds[i]]
    }
    if(!target[fileds[i]] || typeof target[fileds[i]] !== 'object') {
      return undefined
    }
    target = target[fileds[i]]
  }
}

export const stopPropagation = (e) => {
  e.stopPropagation()
}

// 获取缓存的用户信息
export const getUserInfo = () => {
  return  getLocalstorage('loginUserInfo')
}


// 字符串key读取属性
export const numberWithCommas = (value: { toString: () => string }) => {
  return value? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):0;
}
