// 共享的，优先级较低，可被覆盖
const baseConfig = {
  BASE_URL: 'https://wmcs.china-dongcheng.com/customer',
  API_URL: 'https://wmcs.china-dongcheng.com/backstage',
  MANAGER_URL: 'https://wmcs.china-dongcheng.com/manager',
  WORD_URL: 'https://wmcs.china-dongcheng.com/word',
  EXCEL_URL: 'https://wmcs.china-dongcheng.com/excel',

}
const prodConfig = {
  BASE_URL: 'https://wmzs.china-dongcheng.com/customer',
  API_URL: 'https://wmzs.china-dongcheng.com/backstage',
  MANAGER_URL: 'https://wmzs.china-dongcheng.com/manager',
  WORD_URL: 'https://wmzs.china-dongcheng.com/word',
  EXCEL_URL: 'https://wmzs.china-dongcheng.com/excel',
}

const config = {
  // 本地
  local: {
    BASE_URL: 'http://localhost:9005',
    MANAGER_URL: 'http://localhost:9100',
    WORD_URL: 'http://localhost:9200',
    EXCEL_URL: 'http://localhost:9017',

    // ...baseConfig
  },
  // MOCK数据
  mock: {
    ...baseConfig,
    // 接口请求地址
    BASE_URL: ''
  },
  // 连接测试环境
  startTest: {
    ...baseConfig,
  },
  // 连接正式环境
  startPe: {
    ...prodConfig,
  },
  // 编译测试环境
  buildTest: {
    ...baseConfig,
  },
  // 编译正式环境
  buildPe: {
    ...prodConfig,
  }
}

export default config[process.env.REACT_APP_ENV] || config.local  as typeof config.local
