import { FC, PropsWithChildren, useCallback, useState } from 'react'
import { Badge, Dropdown, Drawer } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { LogoutOutlined, DownOutlined, SettingOutlined } from '@ant-design/icons'
import { useContext } from '@/store'
import { useLangContext } from '@/store/Lang'
import {  useShopCarCountContext} from '@/store/ShopCarCount'
import { useLang } from '@/utils/lang'
import * as utils from '@/utils/utils'
import shopcar from '@/assets/images/shopcar.png'
import ChangePassword from './ChangePassword'
import style from './style.module.less'
import {getUserInfo} from "@/utils/common";

type PropType = FC<PropsWithChildren<{
  onShopcar?: () => void
  visible?: boolean
}>>

const langs = [
  {
    label: '简体中文',
    key: 'zhCN',
  },
  {
    label: 'English',
    key: 'enUS',
  }
]

const Head: PropType = ({ onShopcar, visible }) => {
  const context = useContext()
  const userinfo = getUserInfo()
  const langContext = useLangContext()
  const lang = useLang()
  const navigate = useNavigate()
  const location = useLocation()
  const shopcarContext = useShopCarCountContext()
  const [passwordVisible, setPasswordVisible] = useState(false)

  // 选择语言
  const handleLang = useCallback((value) => {
    langContext.changeLanguage(value.key)
  }, [langContext])

  // 前往购物车
  const handleToShopcar = useCallback(() => {
    onShopcar()
  }, [onShopcar])

  // 操作用户模块
  const handleUser = useCallback((target) => {
    if(target.key === 'logout') {
      utils.setLocalstorage('loginUserInfo', null)
      context.userInfo = {}
      navigate('/login?f=' + location.pathname)
    } else if(target.key === 'changePassword') {
      setPasswordVisible(true)
    }
  }, [navigate, context, location])

  return (
    <div className={`flex ${style['head-block']} ${visible ? '' : style['head-hidden']}`}>
      <div className='flex-1'></div>
      <div className='flex mlr-8 ptb-4 align-center'>
        <Badge count={shopcarContext.count} size="small">
          <img className='h-20 cursor-pointer' src={shopcar} alt='' onClick={handleToShopcar} />
        </Badge>
        <div className='ml-30'></div>
        <Dropdown
          menu={{
            items: langs,
            onClick:handleLang
          }}
        >
          <div className={'flex align-center font-13 mr-16' + style.user} style={{width: '100px'}}>
            <span className='mr-4'>{ langs.find(row => row.key === langContext.currentLanguage).label }</span>
            <DownOutlined />
          </div>
        </Dropdown>
        <Dropdown menu={{
          onClick: handleUser,
          items: [
            {
              label: lang.global.changePassword,
              key: 'changePassword',
              icon: <SettingOutlined />
            },
            {
              label: lang.global.logOut,
              key: 'logout',
              icon: <LogoutOutlined />
            },
          ]
        }}>
          <div className={'flex align-center ' + style.user}>
            <span>{userinfo.zhmc}</span>
            <img className={`${style.avatar} ml-8`} src={userinfo.avatar} alt="" />
          </div>
        </Dropdown>
      </div>
      <Drawer title='修改密码' width="80%" onClose={() => setPasswordVisible(false)} open={passwordVisible} destroyOnClose>
        <ChangePassword onClose={() => setPasswordVisible(false)} />
      </Drawer>
    </div>
  )
}

export default Head
