import { Modal } from 'antd'
import axios from 'axios'
import env from '@/config/env'
import {getLocalstorage} from "@/utils/utils";
import {getLang} from "@/utils/lang";
const lang = getLang()
const http = axios.create({
  baseURL: env.BASE_URL,
  // credentials: 'include',
  withCredentials: true,
})
http.interceptors.request.use((request) => {
  if(!request.params) {
    request.params = {}
  }
  request.params.lang = getLocalstorage('lang') || 'zhCN'
  return request
})
http.interceptors.response.use((response) => {
  if(response.data.status === 'ok') {
    response.data.status = 'ok'
    response.data.success = true
  } else if(response.data && response.data.status === 'fail') {
    response.data.status ='fail'
  }
  if(!(response.config as any).uncheck && !response.data.status) {
    Modal.warning({
      title:  lang.Configuration.ts,
      content: response.data.errmsg || '处理异常，请稍后再试'
    })
  }
  return response.data
})

const request = (url, params={}) => {
  return http(url, params) as any
}

export default request
